.selector-map-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.selector-map {
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}