.flex-layout {
  display: flex;
  min-height: fit-content;
  width: 100%;
}

.flex-layout-columns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 20px;
}

.flex-layout-rows {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
}

.flex-layout-content > .recharts-responsive-container {
  min-height: 200px !important;
  max-height: 250px;
  max-width: 400px;
}


@media screen and (max-width: 1024px), (orientation: portrait) {
  .chart-container {
    min-height: 200px !important;
    max-height: 250px !important;
    padding: 0 !important;
  }
  .selector-w-legend {
    max-height: 300px;
  }
  .flex-layout-rows {
    width: 100% !important;
    min-height: fit-content !important;
    padding: 5px;
    justify-content: flex-start;
    /* border-color: transparent !important; */
    gap: 10px;
  }


  .flex-layout-rows > div {
    /* min-height: fit-content !important; */
  }

  .flex-layout-content {
    /* min-height: fit-content !important; */
    /* min-width: 100% !important; */
    /* display: flex;
    justify-content: space-between; */
    /* margin: 10px 0 10px 0 !important; */
    padding-bottom: 0 !important;
  }

  .flex-layout {
    min-height: fit-content !important;
    padding: 0 20px 20px 10px;
    justify-content: flex-start;
  }
  .selector-map-wrapper {
    height: 350px !important;
  }

  .flex-layout-content > .recharts-responsive-container {
    min-height: 200px !important;
  }

  .indicator-trend-wrapper {
    max-height: 100px !important;
    min-height: 80px !important;
  }

  .data-toggle-container {
    min-height: fit-content !important;
  }

  .title-with-trend-data-toggler {
    padding-bottom: 0 !important;
  }

  .title-with-trend-data-toggler > h1 {
    font-size: 2rem !important;
    padding-bottom: 20px;
  }

  .detail-card-header {
    padding-bottom: 0 !important;
  }

}

@media screen and (max-width: 760){
  .flex-layout-content {
    /* min-height: fit-content !important; */
    min-width: 80vw !important;
    /* min-width: 100% !important; */
    /* display: flex;
    justify-content: space-between; */
    /* margin: 10px 0 10px 0 !important; */
    padding-bottom: 0 !important;
  }

}