.summary-card {
  border-top: 1px solid var(--primary-gray-color);
  background-color: var(--white-color);
  height: 100%;
  max-width: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
}

.summary-card-header {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
}

.summary-card-title {
  display: flex;
  align-items:center;
  justify-content: flex-start;
  flex-direction: row;
  height: 30px;
}

.summary-card-title > div {
  height: 20px;
}

.summary-card-header-text {
  /* min-width: fit-content; */
  margin-right: 10px;
  font-size: 16px;
}

.summary-card-sub-header {
  opacity: 0.8;
  font-size: 12px;
  line-height: 16px;
}

.summary-data-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}

.summary-data-wrapper:hover {
  cursor: pointer;
}

.summary-chart,
.summary-data {
  min-height: 100px;
  align-self: center;
  margin: 5px 0;
}

.summary-data {
  display: flex;
  width: 40%;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
}

.summary-chart {
  width: 60%;
  height: 80px;
}

.summary-units,
.summary-indicator-date {
  font-size: .8rem;
  line-height: .9rem;
}

/* -------------------- MEDIA QUERIES -------------------- */
/* Initial Desktop Breakpoint */
@media screen and (max-width: 1410px) {
  .summary-card-header-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 1140px) {
  .summary-card-header-text {
    margin-right: 5px;
  }
}

/* Initial Tablet Breakpoint */
@media screen and (max-width: 1024px) {
  .summary-card {
    height: 240px;
  }
  .summary-card-title {
    font-size: 18px;
  }
}

/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  .summary-card {
    height: fit-content;
    padding: 20px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid var(--primary-gray-color);
  }

  .summary-card-header-text {
    font-size: 14px;
    min-width: fit-content;
    justify-content: flex-start;
  }

  .summary-data {
    min-width: 30%;
    padding-left: 20px;
  }

  .summary-data > .bold-font {
    font-size: 2.5rem;
  }

  .summary-chart {
    width: 70%;
    padding-left: 20px;
    height: 120px;
  }

  .summary-data-wrapper {
    margin: 15px 0 5px 0;
  }
}

@media screen and (max-width: 600px) {
  .summary-card-title > h4 {
    max-width: 120px;
    text-overflow: ellipsis;
  }
}
