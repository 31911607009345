/* -------------------------- Root Variables -------------------------- */
:root {
  --primary-color: #002944;
  --secondary-color: #006aaf;
  --tertiary-color: #72acd2;
  --quaternary-color: #969696;
  --black-color: #333333;
  --white-color: #ffffff;
  --data-primary-color: #006aaf;
  --data-secondary-color: #8dc63f;
  --primary-gray-color: #dfe5e9;
  --secondary-gray-color: #cccccc;
  --success-accent-color: #8dc63f;
  --warning-accent-color: #e46062;
  --desktop-outer-margin: 4%;
  --tablet-outer-margin: 1%;
  --mobile-outer-margin: 0%;
  --desktop-header-height: 100px;
  --tablet-header-height: 100px;
  --mobile-header-height: 75px;
  --desktop-footer-height: 200px;
  --tablet-footer-height: 200px;
  --mobile-footer-height: 200px;
  --smallest-font: .7rem;
  --smaller-font: .9rem;
  --regular-font: 1rem;
  --bigger-font: 1.25rem;
  --biggest-font: 1.5rem;
  --mobile-breakpoint-width: 760px;
  --tablet-breakpoint-width: 1024px;

}
/* ------------------------------ FONTS ------------------------------ */
@font-face {
  font-family: 'RobotoRegular';
  src: local('Roboto-Regular'), url('./fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoBold';
  src: local('Roboto-Bold'), url('./fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoItalic';
  src: local('Roboto-Italic'), url('./fonts/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoBoldItalic';
  src: local('Roboto-BoldItalic'), url('./fonts/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoLight';
  src: local('Roboto-Light'), url('./fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoLightItalic';
  src: local('Roboto-LightItalic'), url('./fonts/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoMedium';
  src: local('Roboto-Medium'), url('./fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoMediumItalic';
  src: local('Roboto-MediumItalic'), url('./fonts/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoBlack';
  src: local('Roboto-Black'), url('./fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoBlackItalic';
  src: local('Roboto-BlackItalic'), url('./fonts/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoThin';
  src: local('Roboto-Thin'), url('./fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoThinItalic';
  src: local('Roboto-ThinkItalic'), url('./fonts/Roboto-ThinItalic.ttf') format('truetype');
}

.leaflet-container {
  height: 100%;
  width: 100%;
  min-width: 200px;
  min-height: 200px;
}
