.about-data-container {
  position: relative;
  min-height: 500px;
}

.about-data-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
}

.about-data-title {
  font-family: 'RobotoBlack';
}

.about-back-link {
  display: flex;
  align-items: center;
}

.about-back-link > h5 {
  margin-right: 5px;
}

.about-back-link:hover {
  cursor: pointer;
  opacity: 0.9;
}

.about-table-header-container {
  margin: 20px 20px 0 20px;
  display: flex;
  border-bottom: 2px solid var(--primary-gray-color);
}

.about-table-header-container > div {
  width: 20%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.1;
}

.about-table-vars-container {
  overflow-y: scroll;
  position: relative;
  max-height: calc(100vh - 455px);
}

.about-table {
  margin: 20px 10px 20px 20px;
  border-collapse: collapse;
}

.about-table-row {
  border-bottom: 2px solid var(--primary-gray-color);
  display: flex;
}

.about-table-row:last-child {
  border-bottom: none;
}

.about-table-row > div {
  padding: 10px;
  font-size: 0.9rem;
  line-height: 1.1;
  width: 20%;
  display: flex;
  align-items: center;
}

#opaque-el {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

/* ---------- MEDIA QUERIES ---------- */
/* Initial Tablet Breakpoint */
@media screen and (max-width: 1024px) {
  .about-table-vars-container {
    max-height: calc(100vh - 560px);
  }
}

/* Initial Mobile Breakpoint */
@media screen and (max-width: 767px) {
  .about-data-container {
    min-height: 350px;
  }

  .about-data-header {
    padding: 10px 0;
  }

  .about-data-title {
    font-size: 16px;
  }

  .about-back-link > h5 {
    font-size: 10px;
  }

  .about-table-vars-container {
    max-height: calc(100vh - 400px);
  }

  .about-table-header-container {
    margin: 10px 0;
  }

  .about-table-header-container > div {
    padding: 2px;
    font-size: 10px;
    line-height: 1;
  }

  .about-table {
    margin: 0;
  }

  .about-table-row > div {
    padding: 5px 10px;
    font-size: 8px;
    line-height: 1;
  }
}
