.share-print-icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 99999999;
}

.sap-icon {
  background-color: var(--secondary-gray-color);
  /* color: var(--black-color); */
  color: var(--white-color);
  box-shadow: none !important;
}

.sap-icon:hover {
  opacity: 0.85;
  cursor: pointer;
}

/* -------------------- MEDIA QUERIES -------------------- */
/* Initial Tablet Breakpoint */
/* @media screen and (max-width: 1024px) {} */

/* Initial Mobile Breakpoint */
/* @media screen and (max-width: 760px) {} */
