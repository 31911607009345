.trend-display-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
}

.upward-trend,
.downward-trend,
.no-data-pill {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  min-height: 30px;
  max-height: 30px;
  min-width: 80px;
  padding: 0;
}

.no-data-pill {
  background-color: lightgray;
  max-width: 100px;
  padding: 10px;
  font-size: .6rem;
  opacity: .5;
  text-align: center;
  line-height: .7rem;
}

.upward-trend > img,
.downward-trend > img {
  margin-right: 10%;
}

.upward-trend {
  background-color: var(--success-accent-color);
}

.downward-trend {
  background-color: var(--warning-accent-color);
}

.upward-trend > h5, .downward-trend > h5 {
  color: var(--white-color);
}

.trend-pill-text {
  line-height: 1;
  /* font-size: var(--smaller-font); */
}

/* ---------- Media Queries ---------- */
/* Initial Desktop Breakpoint */
/* @media screen and (max-width: 1300px) {} */

/* Initial Tablet Breakpoint */
/* @media screen and (max-width: 1024px) {} */

/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  .upward-trend,
  .downward-trend {
    min-width: 100px;
  }
}
