.cp-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.cp-body {
  display: flex;
  flex-direction: row;
  height: fit-content;
}

.cp-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 60px);
  width: 33%;
  padding: 0 2%;
  font-size: 16px;
  line-height: 1.5;
  gap: 20px;
}

.cp-data-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* align-items: center; */
}

.large-indicator-values-container {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 25px;
  height: 100%;
  margin-bottom: 5px;
}

.large-indicator-value > h2.bold-font {
  text-align: center;
  /* font-size:2rem; */
  padding-top: 5px;
}

.large-indicator-value > h5 {
  font-size: 18px;
}

.title-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.horizontal-percentage-indicator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  /* background-color: #e8eeed; */
  background: linear-gradient(to right, #e8eeed , transparent)
}

.horizontal-containers-with-trend-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: .9rem;
  color: #CCCCCC
}

.horizontal-containers-with-trend-header > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100px;
  margin-right: 10px;
}

.cp-chart-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  gap: 10px;
  max-width: 350px;
}

.cp-donut-separator {
  width: 100%;
  border-top: 3px solid var(--primary-gray-color);
  margin: 10px 0;
}

.cp-chart-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.recharts-surface {
  width: fit-content;
}

.cp-donut-legend {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.cp-donut-legend:first-child {
  padding-top: 20px;
}

/* -------------------- MEDIA QUERIES -------------------- */
@media screen and (max-height: 1000px) {
  .horizontal-percentage-indicator {
    margin: 10px 0;
  }
}

@media screen and (max-width: 1280) {
  .detail-card-container, .full-card-container, .full-card-wrapper {
    height: fit-content !important;
  }
}

/* Initial Tablet Breakpoint */
@media screen and (max-width: 1024px), (orientation: portrait) {
  .cp-body {
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }

  .cp-section {
    margin: 0;
    width: 100%;
  }

  .cp-header-title > h2 {
    font-size: 26px;
  }

  .cp-header-title > h3 {
    font-size: 22px;
  }

  .cp-header > div > i {
    font-size: 32px !important;
  }

  .cp-section {
    padding-bottom: 50px;
    background-color: var(--white-color);
  }

  .large-indicator-values-container {
    height: auto;
  }

  .horizontal-percentage-indicator {
    margin: 20px 0 0 0;
  }

  .cp-chart-container, .horizontal-percentage-indicator {
    max-width: 500px !important;
  }

  .cp-chart-container {
    padding-top: 10px;
  }

  .cp-data-wrapper {
    margin-top: 20px;
  }
}

/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  .cp-header-title > h2 {
    font-size: 22px;
  }

  .cp-header-title > h3 {
    font-size: 18px;
  }

  .cp-header > div > i {
    font-size: 28px !important;
  }

  .cp-donut-legend {
    width: 135px;
  }

  .large-indicator-value > h2.bold-font {
  /* text-align: center; */
  font-size:2rem;
  /* padding-top: 5px; */
  }

  .horizontal-percentage-indicator > div > .bold-font {
    font-size: 2rem;
  }

  .large-indicator-values-container {
    margin-bottom: 0;
  }

}