.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px var(--desktop-outer-margin);
}

.footer-container > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 40%;
  padding: 20px 0;
}

.logo-translator-container {
  display: flex;
  align-items: center;
}

.footer-container > div > p {
  margin: 0;
  font-size: 1rem;
}

#bloomberg-logo {
  width: 120px;
  height: auto;
  /* margin-bottom: 10px; */
}

.partner-logos-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 20px;
}
.partner-logos-container > a {
  max-width: calc(100%/4);
}

.partner-logo,
#block-footer-logo {
  margin-right: 10px;
}

.partner-logo {
  width: auto;
  height: 25px;
  align-self: center;
}

#block-footer-logo {
  height: 40px;
}

/* -------------------- MEDIA QUERIES -------------------- */
@media screen and (max-width: 1060px) {
  .footer-container > div {
    max-width: 80%;
  }
  .partner-logo {
    height: 30px;
  }

  #block-footer-logo {
    height: 30px;
  }
}
/* Initial Tablet Sizing */
@media screen and (max-width: 1024px) {
  .footer-container {
    margin-left: var(--tablet-outer-margin);
    margin-right: var(--tablet-outer-margin);
    padding: 10px 0;
    align-items: flex-start;
    /* justify-content: space-between; */
  }

  .footer-container > div {
    max-width: 80%;
    padding: 10px 0 0 10px;
  }

  .partner-logos-container {
    justify-content: space-between;
  }

  .partner-logo {
    height: 30px;
  }

  #block-footer-logo {
    height: 45px;
  }
}

/* Initial Mobile Sizing */
@media screen and (max-width: 760px) {
  .footer-container {
    flex-direction: column;
    justify-content: flex-start;
    margin-left: var(--mobile-outer-margin);
    margin-right: var(--mobile-outer-margin);
    /* Allows all logos to be seen on Safari and Chrome mobile apps */
    margin-bottom: 100px;
  }

  .footer-container > div {
    max-width: 60%;
    padding: 10px 0 0 20px;
  }

  .footer-container > div > p {
    margin: 0;
    font-size: 12px;
  }

  #bloomberg-logo {
    width: 100px;
  }

  .partner-logos-container {
    width: calc(100vw - 25px);
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
    grid-gap: 10px; /* Gap between grid items */
  }

  .partner-logo {
    height: 25px;
  }

  #block-footer-logo {
    height: 45px;
  }
}
