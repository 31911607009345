.landing-wrapper {
  border: 1px solid var(--primary-gray-color);
  background-color: var(--white-color);
  display: flex;
  justify-content: flex-start;
  margin: 20px var(--desktop-outer-margin);
  margin-top: 60px;
}

.landing-container {
  display: flex;
  margin: 40px;
}

.landing-info-container {
  width: 30%;
  border-right: 2px solid #dfe5e9;
}

.landing-info-content {
  padding: 40px 0;
}

.landing-header,
.landing-sub-headers,
.landing-paragraph {
  width: 95%;
  margin-top: 20px;
}

.landing-sub-headers > h5,
.landing-paragraph {
  line-height: 1.5;
}

.landing-cities-container {
  width: 70%;
}

.landing-cities-content {
  padding: 40px 0 40px 40px;
  height: 100%;
}

.landing-city-links {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}

.landing-city-box {
  width: calc(100%/3);
  background-color: #dfe5e9;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.landing-city-box:last-child {
  margin-right: 0;
}

.landing-city-box > h1 {
  color: var(--white-color);
}

/* ---------- MEDIA QUERIES ---------- */
/* Short Desktop or Small Desktop breakpoint */
@media (max-width > 1024px) and (max-height: 1000px), (max-width: 1090px) {
  .landing-info-container {
    width: 40%;
  }

  .landing-cities-container {
    width: 60%;
  }
}

/* Short Desktop Breakpoint */
/* @media (max-width > 1024px) and (max-height: 1000px) {} */

@media screen and (max-height: 750px) {
  .landing-wrapper {
    height: 90%;
  }
}

@media screen and (max-width: 1200px) {
  .landing-info-content {
    margin-right: 20px;
  }

  .landing-city-box {
    width: 100%;
    margin-bottom: 20px;
  }
}

/* Initial Tablet Breakpoint */
@media screen and (max-width: 1024px) {
  .landing-wrapper {
    height: auto;
    margin: var(--tablet-outer-margin);
  }

  .landing-container {
    height: fit-content;
  }

  .landing-city-links {
    flex-direction: column;
  }
}

/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  .landing-wrapper {
    border: 1px solid var(--primary-gray-color);
    background-color: var(--white-color);
    display: flex;
    justify-content: flex-start;
    margin: var(--mobile-outer-margin);
  }

  .landing-container {
    margin: 20px;
    flex-direction: column;
  }

  .landing-info-container,
  .landing-cities-container {
    width: 100%;
  }

  .landing-info-container {
    border-right: none;
    border-bottom: 2px solid #dfe5e9;
    padding-bottom: 20px;
  }

  .landing-info-content {
    padding: 0;
    text-align: center;
  }

  .landing-cities-content {
    padding: 20px 0 0 0;
  }

  .landing-city-links {
    margin-top: 20px;
    flex-direction: column;
  }

  .landing-city-box {
    width: 100%;
    background-color: #dfe5e9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 360px) {
  .landing-city-links {
    flex-direction: column;
  }

  .landing-city-box {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* @media screen and (max-width: 285px) {} */
