.hbc-container {
  width: 100%;
  /* max-height: 100%; */
  /* max-height: 60vh; */
  overflow: auto;
  scroll-behavior: smooth;

}

.hbc-title {
  width: 70%;
  margin: 20px 0;
  font-family: 'RobotoBold';
}

.hbc-row-container {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
}

.hbc-row-label {
  display: flex;
  align-items: center;
  width: 40%;
  min-width: 150px;
  height: fit-content;
  overflow: hidden;
  word-break: normal;
  text-overflow: ellipsis;
}

.hbc-bar-container {
  width: 60%;
  display: flex;
}

.hbc-bar {
  max-height: 20px;
  min-height: 20px;
  display: flex;
}

.hbc-row-value {
  margin-left: 10px;
  display: flex;
  align-items: center;
  color: #006aaf;
}

@media screen and (max-width: 1280px) {
  .hbc-row-label {
    min-width: 100px;
    font-size: var(--smallest-font);
  }
}

@media screen and (max-width: 760px), (orientation: portrait) {
  .hbc-container {
    /* max-height: 100%; */
    overflow: auto !important;
  
  }
  .hbc-row-label {
    min-width: 100px;
  }
}