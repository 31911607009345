.data-toggle-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: fit-content;
  /* max-width: 200px; */
  min-height: fit-content !important;
  padding: 0 0 5px 5px;
}

.data-toggle-container > p {
  font-size: .8rem;
  padding: .5rem;
}

.default-data-toggle-text {
  line-height: .8rem;
  margin: 0;
  justify-self: flex-end;
  vertical-align: bottom;
  font-weight: bold;
}

.data-toggle  {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  max-width: 350px;
  min-width: 150px;
  min-height: fit-content;
  padding: 10px;
}

.data-toggle > h5 {
  padding: 0;
  margin: 0;
  width: fit-content;
  max-width: 120px;
  text-align: center;
}

.toggle-el {
  margin: 0 10px;
}
