.overview-title,
.overview-body {
  padding: 0 5%;
}

.overview-title {
  width: 100%;
  padding-bottom: 0;
  margin: 30px 0 20px 0;
}

.overview-title > h1 > span {
  font-size: 2.5rem;
}

.overview-text-body > h5,
.overview-text-body > p,
.overview-contact-link,
.overview-contact-link > span,
.overview-dropdown-header > h5 {
  line-height: 1.8;
  color: var(--white-color);
}

.overview-text-body > h5,
.overview-dropdown-header > h5 {
  font-size: 16px;
}

.overview-text-body > p,
.overview-contact-link,
.overview-contact-link > span {
  font-size: 14px;
}

.overview-text-body {
  margin-bottom: 20px;
  max-width: 92%;
}

.overview-dropdown-header {
  display: flex;
  align-items: center;
}

.overview-dropdown-header:hover {
  cursor: pointer;
}

.overview-contact-link:hover {
  color: var(--white-color);
}

.overview-arrow-container {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
}

/* -------------------- MEDIA QUERIES -------------------- */
/* Initial Tablet Breakpoint */
@media screen and (max-width: 1024px) {
  .overview-body {
    padding: 20px;
  }

  .overview-title {
    margin-top: 20px;
    padding-left: 20px;
  }

  .overview-title > h1 > span {
    font-size: 2rem;
    margin-bottom: 0;
    padding-left: 0;

  }
  .overview-title > h1 {
    margin-bottom: -20px;
  }
  
}

/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  .overview-wrapper {
    width: 100vw;
  }

  .overview-body {
    padding: 10px;
  }
}
