#dashboard-header {
  height: 100%;
  display: flex;
  width: 100%;
  background-color: var(--primary-color);
}

.title-container {
  width: 350px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  margin-left: var(--desktop-outer-margin);
  color: var(--white-color);
}

/* .title-container > a {
  display: flex;
  flex-direction: column;
} */

#header-logo {
  width: 80%;
  height: auto;
}

.about-container {
  width: calc(100% - var(--desktop-outer-margin) - 350px);
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: var(--desktop-outer-margin);
}

.about-link-container,
.selected-about-link-container {
  padding: 5px 20px;
}

.about-link {
  font-size: 16px;
  color: var(--white-color);
}

.about-link:hover {
  opacity: 0.75;
  color: var(--white-color);
}

.selected-about-link-container {
  border-bottom: 5px solid var(--white-color);
}

#burger-menu-button {
  color: var(--white-color);
}

.nav-dropdown-menu {
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  right: calc(var(--mobile-outer-margin) + 3px);
  top: calc((var(--mobile-header-height) / 2) + 15px);
  /* right: calc(var(--tablet-outer-margin) + 3px);
  top: calc((var(--tablet-header-height) / 2) + 15px); */
  height: 40%;
  width: 275px;
  padding: 5px 0;
  z-index: 9999999;
  border: 1px solid var(--primary-gray-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.header-nav-icon {
  width: 16px;
  margin-right: 10px;
}

.nav-link {
  color: var(--black-color);
  width: 75%;
  height: auto;
  margin: auto;
  text-align: center;
}

.selected-nav-link {
  color: var(--primary-color);
  font-family: 'RobotoBold';
  border-bottom: 5px solid var(--primary-color);
}

/* -------------------- MEDIA QUERIES -------------------- */
/* Initial Tablet Breakpoint */
@media screen and (max-width: 1024px)  {
  .title-container {
    width: calc(48% - (var(--tablet-outer-margin)));
    /* padding-left: var(--tablet-outer-margin); */
  }

  .about-container {
    width: calc(48% - (var(--tablet-outer-margin)));
    padding-right: var(--tablet-outer-margin);
  }
}

/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  .title-container {
    /* padding-left: var(--mobile-outer-margin); */
    /* width: 40%; */
  }

  #header-logo {
    width: 80%;
    height: auto;
  }

  .about-container {
    width: calc(50% - (var(--mobile-outer-margin)));

    padding-right: 20px;
  }

  /* #header-logo {
    height: 30%;
  } */
}
