h1,
h2,
h3,
h4,
h5 {
  font-family: 'RobotoRegular', sans-serif;
  /* font-weight: normal; */
  margin: 0;
  padding: 0;
  line-height: 1;
  color: var(--black-color);
}

/* h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
} */

h1 {
  font-size: var(--biggest-font);
}

h2 {
  font-size: var(--bigger-font);
}

h3 {
  font-size: var(--regular-font);
}

h4 {
  font-size: var(--smaller-font);
}

h5 {
  font-size: var(--smallest-font);
}

a {
  color: var(--black-color);
}

a:hover {
  color: var(--black-color);
}

.App {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  font-family: 'RobotoRegular', sans-serif;
}

#layout {
  background-image: url('/public/images/background_300x300.png');
  width: 100vw;
  height: 100vh;
}

.full-card-wrapper {
  width: 100%;
  display: flex;
  /* height: calc(100vh - 500px); */
}

.full-card-container {
  border: 1px solid var(--primary-gray-color);
  width: 100%;
  background-color: var(--white-color);
  display: flex;
  justify-content: flex-start;
}

.section-bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
}

.chart-tooltip {
  background-color: var(--white-color);
  border: 1px solid var(--primary-gray-color);
  padding: 10px;
}

.chart-container {
  /* padding: 20px; */
  height: 100%;
  max-height: fit-content;
  width: 100%;
}

.primary-font {
  font-family: 'RobotoRegular', sans-serif;
}

.bold-font {
  font-family: 'RobotoBold';
}

.italic-font {
  font-family: 'RobotoItalic';
}

.thin-font {
  font-family: 'RobotoThin';
}

.thin-italic-font {
  font-family: 'RobotoThinItalic';
}

.medium-font {
  font-family: 'RobotoMedium';
}

.medium-italic-font {
  font-family: 'RobotoMediumItalic';
}

.black-font {
  font-family: 'RobotoBlack';
  color: var(--black-color);
}

.black-italic-font {
  font-family: 'RobotoBlackItalic';
}

.half-opacity {
  opacity: 0.5;
}

.underline-container {
  border-bottom: 3px solid var(--primary-gray-color);
}

path:focus {
  outline: none;
}

.in-text-link {
  text-decoration: underline;
}

.in-text-link:hover {
  cursor: pointer;
  opacity: 0.8;
  text-decoration: none;
}

.consent-button {
  height: 32px;
  border: 1px solid var(--black-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 32px;
}

.consent-button:first-child {
  margin-right: 20px;
}

.consent-button:hover {
  cursor: pointer;
  background-color: var(--black-color);
  color: var(--white-color);
}

 g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis > text > tspan {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
 }

/* -------------------- MEDIA QUERIES -------------------- */
/* Initial Tablet Breakpoint */
@media screen and (max-width: 1024px) {
  .consent-button {
    width: 100%;
  }

  .consent-button:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }
  
}

/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  .full-card-wrapper {
    margin: 10px 0;
  }
  .full-card-container {
    margin-left: var(--mobile-outer-margin);
    margin-right: var(--mobile-outer-margin);
    height: fit-content !important;
  }
}

@media screen and (orientation: portrait) {
  .full-card-container {
    /* margin-left: var(--tablet-outer-margin);
    margin-right: var(--tablet-outer-margin); */
    height: fit-content !important;
  }
}
