.home-wrapper {
  display: flex;
  min-height: fit-content;
}

.overview-wrapper {
  width: 350px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  display: flex;
  flex-direction: column;
}

.summary-wrapper {
  width: calc(100% - 350px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--white-color);
  border-top: 2px solid var(--primary-gray-color);
  border-right: 2px solid var(--primary-gray-color);
  border-bottom: 2px solid var(--primary-gray-color);
  padding: 10px;
}

.summary-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding-left: 10px;
}

.summary-title-container {
  display: flex;
  align-items: center;
}

.summary-title-container > h5 {
  font-size: 0.9rem;
}

.large-summary-title {
  font-family: 'RobotoBlack';
  margin-right: 5px;
}

.summary-cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  flex-grow: 1;
}

/* -------------------- MEDIA QUERIES -------------------- */
/* Initial Desktop Breakpoint */
@media screen and (max-width: 1300px) {
  .summary-cards-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

/* @media screen and (max-height: 1000px) {
} */

/* Initial Tablet Breakpoint */
@media screen and (max-width: 1024px) {
  .home-wrapper {
    flex-direction: column;
    height: fit-content;
  }

  .overview-wrapper,
  .summary-wrapper {
    width: 100%;
    margin: 0;
    
  }

  .summary-wrapper {
    height: fit-content;
    /* min-height: 620px; */
  }

  .large-summary-title {
    font-size: 22px;
  }
}

/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  .home-wrapper {
    height: fit-content;
    margin: 0;
    width: 100vw;
  }

  .overview-wrapper,
  .summary-wrapper {
    width: 98vw;
    padding-right: 10px;
    padding-right: 10px;
    /* padding-left: var(--mobile-outer-margin);
    padding-right: var(--mobile-outer-margin); */
  }

  .overview-wrapper {
    background-image: none;
    background-color: var(--secondary-color);
    z-index: 999999;
    color: var(--white-color);
    margin: 0;
    padding-bottom: 0;
    padding-left: 10px;
  }

  .summary-wrapper {
    height: fit-content;
  }

  .summary-content {
    background-color: transparent;
    border: none;
    padding-left: 0;
    padding-right: 0;
  }

  .summary-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: center;
  }

  .large-summary-title {
    font-size: 20px;
  }

  .summary-cards-container {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
    height: fit-content;
    padding-bottom: 20px;
    flex-grow: unset;
    gap: 10px;
  }
}
