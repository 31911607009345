.ucm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.ucm-container {
  background-color: var(--white-color);
  border: 1px solid var(--primary-gray-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  min-width: 50%;
  max-height: 80%;
  min-height: 50%;
  overflow-y: auto;
}

.ucm-content {
  padding: 20px;
}

.ucm-close-container {
  display: flex;
  justify-content: flex-end;
  /* margin-bottom: 10px; */
}

.ucm-close-button:hover {
  cursor: pointer;
  opacity: 0.9;
}

.ucm-title {
  font-size: 20px;
  font-weight: 300;
  margin: 20px 0;
}

.ucm-section-title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}

.ucm-paragraph {
  font-size: 16px;
  line-height: 1.3;
  margin: 0 0 24px 0;
}

.ucm-list {
  list-style-type: none;
  font-size: 16px;
  margin: 20px 0 40px 0;
}

.ucm-link {
  text-decoration: underline;
}

.ucm-link:hover {
  opacity: 0.9;
}

.ucm-button-container {
  display: flex;
  margin: 40px 0 20px 0;
}

@media screen and (max-width: 1024px) {
  .ucm-container {
    max-height: 98%;
    max-width: 98%;
  }

  .ucm-button-container {
    flex-direction: column;
  }
}

/* Initial Mobile Breakpoint */
/* @media screen and (max-width: 760px) {} */
