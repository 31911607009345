.dashboard-title {
  line-height: 1;
  display: flex;
  flex-direction: column;
  color: var(--white-color);
}

/* -------------------- MEDIA QUERIES -------------------- */
/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  .dashboard-title {
    font-size: 2rem;
  }
}
