.ucb-container {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 15%;
  padding: 0 var(--desktop-outer-margin);
  background-color: var(--white-color);
  border-top: 1px solid var(--primary-gray-color);
  z-index: 999999;
}

.ucb-content-container {
  width: 100%;
  font-size: 16px;
  display: flex;
  line-height: 1.3em;
}

.ucb-content-container:first-child {
  padding-right: 50px;
}

/* Initial Tablet Breakpoint */
@media screen and (max-width: 1024px) {
  .ucb-container {
    height: 20%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 var(--tablet-outer-margin);
  }
  .ucb-content-container {
    flex-direction: column;
  }

  .ucb-content-container:first-child {
    padding-right: 0;
    padding-bottom: 20px;
  }
}

/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  .ucb-container {
    height: 35%;
    padding: 0 var(--mobile-outer-margin);
  }
}
