.compare-dropdown-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  /* min-width: 100%; */
  /* padding: 5px 5px 5px 20px; */
}

.compare-dropdown-container > h4 {
  min-width: 70px;
  /* max-width: 70px; */
  padding-right: 10px;
  align-items: flex-end;
  font-size: .8rem;
  text-align: end;
  /* text-wrap: wrap; */
}

.align-left {
  text-align: left !important;
}

.compare-dropdown-legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 250px ; */

  min-width: calc(100% - 80px);
  /* margin: 10px 20px 0 0; */
  padding: 10px 10px 0 0;
}

.main-value-container,
.option-selection-container {
  /* width: 50%; */
  margin-bottom: 10px;
}

.main-value-container > h5 {
  min-width: 70px;
  font-size: .8rem;
}

.option-selector > div > h5 {
  min-width: 100px;
  font-size: var(--smallest-font);
}

.main-value-container {
  /* margin-right: 10px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  /* flex-grow: 1; */
  max-width: 250px;
  /* min-width: 100%; */
}

.option-selection-container {
  /* width: 100%; */
  /* min-width: 300px; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.option-selector {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: var(--primary-gray-color);
  min-width: fit-content;
  /* margin-right: 20px; */
}

.option-selector > div {
  max-width: calc(100% - 20px);
}

.option-selector:hover {
  cursor: pointer;
}

.option-selector > div {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

/* .option-selector > div > * {
  margin-right: 10px;
} */

.compare-dropdown-search {
  display: flex;
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: var(--smallest-font);
  font-weight: 700;
  max-width: calc(100% - 30px);
}

.compare-dropdown-search::placeholder {
  color: inherit;
  width: 100%;
  font-weight: 100;
  font-style: italic;
}

.selected-option-svg,
.main-value-svg {
  margin-left: 10px;
}

.selected-option-svg > rect {
  fill: var(--data-secondary-color);
}

.main-value-svg > rect {
  fill: var(--data-primary-color);
}

.compare-options-container {
  background-color: var(--primary-gray-color);
  position: absolute;
  width: 100%;
  top: 20px;
  padding: 0 20px;
  z-index: 999;
  list-style: none;
  max-height: 150px;
  overflow: auto;
}

.compare-selected-option,
.compare-unselected-option {
  padding: 10px 0;
}

.compare-unselected-option > h5{
  font-weight: 100;
  font-size: var(--smallest-font);
}

.compare-selected-option:hover,
.compare-unselected-option:hover {
  cursor: pointer;
}

.compare-unselected-option:hover {
  opacity: 0.5;
}

@media screen and (min-width: 1200) {
  .compare-dropdown-legend {
    width: calc(100% - 20px);
  }
}


/* -------------------- MEDIA QUERIES -------------------- */
/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  .compare-dropdown-container {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }

  .compare-options-container {
    top: 24px;
  }
}
