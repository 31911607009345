.selector-w-legend {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  /* gap: 10px; */
  padding: 2px;
  box-shadow: inset rgba(149, 157, 165, 0.2) 0px 8px 24px;;
  /* justify-content: flex-start; */
  width: 100%;
  height: 100%;
  max-height: 220px;
  overflow: auto;
}

.selector-w-legend-label,
.selector-w-legend-selected-legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 30px;
  padding: 5px 2px 5px 2px;
}

.selector-w-legend-label {
  width: 100%;
}

.selector-w-legend-selected-legend-item {
  width: calc(100% - 2px);
}

/* .selector-w-legend-selected-legend-item {
  padding-top: 10px;
} */

.selector-w-legend-label > i {
  width: 30px;
}

.selector-w-legend-label > h5 {
  width: calc(100% - 50px);
  padding-right: 10px;
}

.selector-w-legend-selected-legend {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
  margin-left: 15px;
  border-left: solid black 2px;
  padding-left: 20px;
}

.selector-w-legend-selected-legend-item > h5 {
  width: calc(100% - 2px);
  padding-right: 10px;

}
.selector-w-legend-label-selected {
  background-color: var(--primary-gray-color);
}