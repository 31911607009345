.indicator-trend-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.indicator-trend-top-label {
  width: 100%;
  height: 40px;
}

.indicator-data-body {
  display: flex;
  flex-wrap: wrap;
  /* width: fit-content; */
  width: 100%;
  height: fit-content;
}

.indicator-value-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 100%;
  /* flex-wrap: wrap; */
}

.indicator-value-container > div {
  /* transform: scale(.8); */
}

.indicator-value-container > h1 {
  margin-right: 0px;
  padding-right: 5%;
  min-width: fit-content;
}





/* -------------------- MEDIA QUERIES -------------------- */
@media screen and (max-width: 1200px) {
  .indicator-trend-top-label {
    height: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .indicator-trend-top-label {
    height: 100%;
  }
}
/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  .indicator-value-container > .bold-font {
    font-size: 2.5rem;
  }
}