.info-icon {
  color: var(--secondary-gray-color) !important;
}

.info-icon:hover {
  cursor: pointer;
  opacity: 0.8;
}

.info-icon-popup-container > * {
  margin-bottom: 10px;
  line-height: 1;
}

.info-icon-text-container > * {
  font-family: 'RobotoRegular';
}

.info-icon-text-container > *,
.info-icon-popup-text {
  font-size: 12px;
}

/* ---------- Media Queries ---------- */
/* Initial Mobile Breakpoint */
/* @media screen and (max-width: 1024px) {} */
