#layout {
  position: relative;
  height: 100vh;
}

#content {
  height: 100vh;
  /* overflow-y: auto; */
}

#header-container {
  height: var(--desktop-header-height);
}

#scrollable-body {
  overflow-y: auto;
  /* height: calc(100vh - var(--desktop-header-height)); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#section-container {
  padding: 0 var(--desktop-outer-margin);
  margin: 20px 0;
  /* height: fit-content; */
  /* max-height: 100vh - 650px !important; */
}

#footer {
  width: 100%;
  background-color: var(--black-color);
  color: var(--white-color);
}



#beta-tag {
  /* color: var(--white-color); */
  align-self: flex-end;
  text-align: right;
  line-height: .2rem;
  width: 80%;
  font-size: .7rem;
  /* padding-bottom: 9px; */
  /* padding-left: 4px; */

}

#beta-tag > span {
  background-color: var(--white-color);
  color: var(--primary-color);
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 2px;
  
  /* font-weight: 700; */
}

/* -------------------- MEDIA QUERIES -------------------- */
/* Initial Tablet Breakpoint */
@media screen and (max-width: 1024px) {
  #header-container {
    height: var(--tablet-header-height);
  }

  #scrollable-body {
    overflow-y: auto;
    height: calc(100vh - var(--tablet-header-height));
    /* height: 100%; */
  }

  #section-container {
    height: auto;
    padding: 0 var(--tablet-outer-margin);
  }
}

/* Initial Mobile Breakpoint */
@media screen and (max-width: 760px) {
  #header-container {
    height: var(--mobile-header-height);
  }

  #scrollable-body {
    overflow-x: hidden;
    width: 100vw;
    height: calc(100vh - var(--mobile-header-height));
  }

  #section-container {
    width: 100vw;
    padding: 0;
    margin: 0;
  }
}
