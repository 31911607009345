.indicator-map-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  /* padding-bottom: 30px !important; */

}

.indicator-map {
  height: 100%;
  min-height: 200px;
  padding-top: 20px;
  /* padding-bottom: 30px !important; */
}

.map-legend > h4 {
  text-overflow: ellipsis;
  font-size: 1rem;
  text-align: center;
}

.map-legend {
  background-color: var(--white-color);
  border: 2px solid var(--primary-gray-color);
  height: fit-content;
  width: fit-content;
  max-width: 150px;
  margin: 0 10px 20px 0;
  padding: 10px;
  z-index: 999999;
  position: absolute;
  right: 0;
  bottom: 0;
}

.legend-close-icon {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: middle;
  height: fit-content;
  margin-top: -5px;
  margin-bottom: 5px;

}

.legend-bins {
  list-style: none;
  margin: 10px 5px 5px 5px;
  padding: 0;
}

.legend-bin {
  display: flex;
  align-items: center;
  margin: 5px;
}

.legend-bin > svg {
  margin-right: 10px;
}

.indicator-map-tooltip {
  width: 120px;
  padding: 5px;
  text-align: center;
  /* text-overflow: ellipsis; */
}

.indicator-map-tooltip > p {
  max-width: 110px;
  white-space: pre-wrap;
  word-wrap: normal;
  margin: 0 0 10px 0;
  padding: 0;
  /* line-height: 10px;
  font-size: .9rem; */
}

.indicator-map-tooltip > strong {
  max-width: 110px;
  /* white-space: pre-wrap;
  word-wrap: normal; */
  /* margin: 10px 0 0 0; */
  /* padding: 0; */
  /* line-height: 10px; */
  font-size: 1.5rem;
}
/* -------------------- MEDIA QUERIES -------------------- */
/* Initial Desktop Breakpoint */
@media screen and (max-width: 1465px) {
  .indicator-map {
    height: 350px;
    width: 100%;
  }
}
/* Initial Tablet Breakpoint */
@media screen and (max-width: 1280px) {
  .indicator-map {
    height: 400px;
    width: 100%;
  }
}

/* Initial Mobile Breakpoint */
@media screen and (max-width: 767px) {
  .indicator-map {
    height: 300px;
  }

  .dropdown-indicators-container {
    position: relative;
    margin-top: 0;
    height: 250px;
  }
}
