.desktop-tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  position: relative;
  justify-content: space-between;
}

.desktop-tabs > a {
  font-size: 1rem;
}

.desktop-tabs:hover {
  cursor: pointer;
}

.selected-tab,
.unselected-tab {
  height: 50px;
  width: 100%;
  /* width: 20%; */
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
}

.selected-tab {
  color: var(--white-color);
  position: relative;
  top: 1.5px;
  /* border-top: 2px solid var(--primary-gray-color);
  border-left: 2px solid var(--primary-gray-color);
  border-right: 2px solid var(--primary-gray-color); */
}

.unselected-tab {
  color: var(--black-color);
}

.selected-tab:hover,
.unselected-tab:hover {
  color: var(--white-color);
}

.home-tab {
  color: var(--white-color);
  /* border: 2px co */
}

.home-tab > img {
  color: var(--white-color);
}

.icon-and-text-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.tab-home-icon {
  margin-right: 10px;
  height: 1.5rem;
  width: auto;
}

/* -------------------- MEDIA QUERIES -------------------- */
/* Initial Tablet Breakpoint */
@media screen and (max-width: 1024px) {
  .desktop-tabs {
    gap: 5px;
    justify-content: space-between
  }

  .desktop-tabs > a {
    font-size: .8rem;
  }

  .selected-tab,
  .unselected-tab {
    width: calc(
      100% / 8
    ); /* Width of container divided by number of tabs -- adjust when tabs added/removed */
    height: 40px;
    font-size: 12px;
    padding: 0;
    line-height: 1.25;
    width: 100%;
  }
}
