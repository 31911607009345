.sib-container {
  margin-top: 40px;
  padding: 10px;
}

.sib-label-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* ---------- Media Queries ---------- */
/* Initial Mobile Breakpoint */
@media screen and (max-width: 1024px) {
  .sib-container {
    margin: 0 20px;
  }
}
